<!--
 * @Description:  财务综合管理 退款记录 reFundManagement
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2021-08-30 11:45:47
 * @LastEditors: zhoucheng
-->
<template>
  <div class='mainBody'>
    <!-- 图表部分 -->
    <el-row class="middle">
      <el-row class="firstPie">
        <el-row class="firstPieTitile">退款渠道分布</el-row>
        <el-row class="firstPieContent">
          <el-row id="main"
                  class="firstPieItem"
                  :style="{width: '100%', height: '200px'}"></el-row>
        </el-row>
      </el-row>
      <el-row class="secondPie">
        <el-row class="secondPieTitle">退款金额分析</el-row>
        <el-row class="secondPieContent">
          <el-row class="secondPieContent-left">
            <el-row class="tilte">总退款金额</el-row>
            <el-row class="content">
              <span class="money">{{refundMoneyAll}}</span>
              <span class="moneyText">元</span>
            </el-row>
            <el-row class="tilte">今日总退款金额</el-row>
            <el-row class="content">
              <span class="money">{{refundMoneyToday}}</span>
              <span class="moneyText">元</span>
            </el-row>
          </el-row>
          <el-row class="secondPieContent-mid"></el-row>
          <el-row id="mainSecond"
                  class="secondPieContent-right"
                  :style="{width: '570px', height: '200px'}"></el-row>
        </el-row>
      </el-row>
    </el-row>
    <!-- 查询部分 -->
    <el-row class="topSelectTwoLine">
      <el-form :inline="true"
               :model="searchForm">
        <el-row class="topFirstLine">
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="订单编号">
              <el-input v-model="searchForm.orderId"
                        clearable
                        placeholder="请输入订单编号"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label-width="100px"
                          label="订单支付编号">
              <el-input v-model="searchForm.paymentId"
                        clearable
                        placeholder="订单支付编号"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="退款渠道">
              <el-select v-model="searchForm.payType"
                         class="dt-form-width"
                         placeholder="请选择">
                <el-option v-for="item in refundChannelList"
                           :key="Number(item.code)"
                           :label="item.name"
                           :value="Number(item.code)">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="退款进度">
              <el-select v-model="searchForm.refundResult"
                         class="dt-form-width"
                         placeholder="请选择">
                <el-option v-for="item in refundPlanList"
                           :key="Number(item.code)"
                           :label="item.name"
                           :value="Number(item.code)">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="topSecondLine">
          <el-col :span="6">
            <el-form-item label="退款时间"
                          label-width="90px">
              <el-date-picker v-model="Time"
                              type="daterange"
                              align="right"
                              unlink-panels
                              value-format="yyyy-MM-dd HH:mm:ss"
                              range-separator="-"
                              start-placeholder="开始日期"
                              end-placeholder="结束日期"
                              :default-time="['00:00:00', '23:59:59']"
                              :picker-options="pickerOptions">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-row class="topButton">
            <el-button @click="handleClickQuery"
                       type="primary">查 询</el-button>
            <el-button @click="resetForm"
                       type="info"
                       style="margin-left:17px">重 置</el-button>
          </el-row>
        </el-row>
      </el-form>
    </el-row>
    <!-- 表格 -->
    <el-row class="tableRow">
      <el-row class="tableTitle">
        <span>退款记录</span>
        <el-button type="info"
                   class="tableTitleButton"
                   @click="exportFile">
          导 出
        </el-button>
      </el-row>
      <el-row class="tableContent">
        <!-- 表格 -->
        <dt-table :tableColumnList="tableColumnList"
                  :data="tableList"
                  :map="listMap"
                  :tableConfig='tableConfig'
                  :paginationConfig='paginationConfig'
                  @getList="getList">
          <template slot="isFirst">
          </template>
          <template slot="operating">
            <el-table-column label="操作"
                             class-name='class-name'>
              <template slot-scope="scope">
                <el-col :span="8">
                  <el-button @click="handleClickWatch(scope.row)"
                             type="text"
                             size="medium">详情</el-button>
                </el-col>
              </template>
            </el-table-column>
          </template>
        </dt-table>
      </el-row>
    </el-row>
    <!-- 详情 -->
    <el-dialog title="详情"
               :visible.sync="detailDialogVisible"
               :close-on-click-modal="false"
               append-to-body
               @close="queryRefundlist">
      <el-form :model="detailFormList"
               :inline="true"
               ref="detailFormList"
               label-width="150px"
               size="small">
        <el-row justify="space-around">
          <el-col :span="12">
            <el-form-item label="退款编号">
              <el-input v-model="detailFormList.refundId"
                        class="dt-form-width"
                        readonly></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="退款时间">
              <el-input v-model="detailFormList.refundTime"
                        class="dt-form-width"
                        readonly></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="订单编号">
              <el-input v-model="detailFormList.orderId"
                        class="dt-form-width"
                        readonly></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="支付记录编号">
              <el-input v-model="detailFormList.paymentId"
                        class="dt-form-width"
                        readonly>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="退款渠道">
              <el-input v-model="detailFormList.payTypeName"
                        class="dt-form-width"
                        readonly></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="退款金额">
              <el-input v-model="detailFormList.refundMoneyAmount"
                        class="dt-form-width"
                        readonly></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row justify="space-around">
          <el-col :span="12">
            <el-form-item label="退款进度">
              <el-input v-model="detailFormList.refundResultName"
                        class="dt-form-width"
                        readonly>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row type="flex"
              justify="end">
        <el-col :span="12"
                class="dt-button-box">
          <el-button type="info"
                     class="popBtn"
                     @click="detailDialogVisible = false">取 消</el-button>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import echarts from 'echarts'
import dtTable from '@/components/table.vue'
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
    dtTable,
  },
  data () {
    //这里存放数据
    return {
      pieseriesData: [], //饼图数据
      pielegendData: [], //饼图图例数据
      paginationConfig: { layout: 'prev,sizes,pager,next,jumper', total: 0 },
      tableConfig: { border: true },
      listMap: {
        pageNum: 1,
        pageSize: 15,
      },
      // 列名
      tableColumnList: [
        {
          prop: 'refundId',
          label: '退款单编号',
        },
        {
          prop: 'refundTime',
          label: '退款时间',
        },
        {
          prop: 'orderId',
          label: '订单编号',
        },
        {
          prop: 'paymentId',
          label: '支付记录编号',
        },
        {
          prop: 'payTypeName',
          label: '退款渠道',
        },
        {
          prop: 'refundMoneyAmount',
          label: '退款金额',
        },
        {
          prop: 'refundResultName',
          label: '退款进度',
        },
      ],
      // 表格数据
      tableList: {
        list: [],
      },
      // 搜索表单
      searchForm: {
        orderId: "",
        paymentId: "",
        payType: "",
        refundResult: "",
        startTime: "",
        endTime: ""
      },
      Time: null, //顶部搜索条件时间框
      pageNum: 1,
      pageSize: 15,// 初始化分页
      refundChannelList: [],// 退款渠道
      refundPlanList: [],// 退款进度
      payTypesList: [],//退款方式图表
      refundTimesToday: 0, //今日退款次数(成功)
      refundTimesAll: 0, //总退款次数(成功)
      refundMoneyToday: 0, //今日退款金额(成功)，单位元
      refundMoneyAll: 0, //总退款金额(成功),单位元

      detailFormList: {},// 详情列表
      detailDialogVisible: false, // 详情弹窗显示
      //日期快速选择
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近一个月',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近三个月',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            },
          },
        ],
      },
    };
  },
  //监听属性 类似于data概念
  computed: {
  },
  //监控data中的数据变化
  watch: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created () { },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.queryRefundlist()
    this.queryChartsData()
    this.queryRefundChanel()
    this.queryRefundPlan()
  },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
  //方法集合
  methods: {
    // 导出
    exportFile () {
      this.$axios.post(this.$downloadOrderBaseUrl + "Refund/export", {}, {
        responseType: "blob",
        headers: {
          'userToken': localStorage.getItem("userToken")
        }
      }).then(res => {
        let blob = new Blob([res.data], {
          type: "application/vnd.ms-excel",
        }); // 2.获取请求返回的response对象中的blob 设置文件类型，这里以excel为例
        let url = window.URL.createObjectURL(blob); // 3.创建一个临时的url指向blob对象
        // 4.创建url之后可以模拟对此文件对象的一系列操作，例如：预览、下载
        let a = document.createElement("a");
        a.href = url;
        a.download = "退款记录.xlsx";
        a.click();
        // 5.释放这个临时的对象url
        window.URL.revokeObjectURL(url);
      })
    },
    // 列表渲染
    queryRefundlist () {
      this.searchForm["pageNum"] = this.pageNum
      this.searchForm["pageSize"] = this.pageSize
      this.$reFundManagement.queryRefundlist(this.searchForm).then(res => {
        this.tableList.list = res.resultEntity.list
        this.paginationConfig.total = res.resultEntity.total
      })
    },
    // 查询
    handleClickQuery () {
      this.pageNum = 1
      this.listMap.pageNum = 1
      if (this.Time) {
        this.searchForm.startTime = this.Time[0]
        this.searchForm.endTime = this.Time[1]
      } else {
        this.searchForm.startTime = ''
        this.searchForm.endTime = ''
      }
      this.queryRefundlist()
    },
    // 重置
    resetForm () {
      this.pageNum = 1
      this.listMap.pageNum = 1
      this.searchForm = {}
      this.Time = []
      this.queryRefundlist()
    },
    //分页
    getList (change) {
      this.pageSize = change.pageSize
      this.pageNum = change.pageNum
      this.listMap.pageNum = change.pageNum
      this.queryRefundlist()
    },
    // 点击详情
    handleClickWatch (row) {
      console.log(row);
      this.detailDialogVisible = true
      this.detailFormList = row
    },
    // 图表信息查询
    queryChartsData () {
      this.$reFundManagement.getRefundCharts({}).then(res => {
        this.refundTimesToday = res.resultEntity.refundTimesToday
        this.refundTimesAll = res.resultEntity.refundTimesAll
        this.refundMoneyToday = res.resultEntity.refundMoneyToday
        this.refundMoneyAll = res.resultEntity.refundMoneyAll
        this.payTypesList = res.resultEntity.payTypes
        let total = 0
        this.payTypesList.forEach((item) => {
          total = total + item.count
        })
        this.payTypesList.forEach((item) => {
          let json = {
            value: item.count, name: item.payTypeName,
            percentage: parseInt((item.count / total) * 100)
          }
          this.pielegendData.push(item.payTypeName)
          this.pieseriesData.push(json)
        })
        this.refundPlanPie()
        this.refundChannelPie()
      }).catch(
        this.refundPlanPie(),
        this.refundChannelPie()
      )
    },
    // 退款渠道分布
    refundChannelPie () {
      var myecharts = echarts.init(document.getElementById('main'));
      var data = this.pieseriesData;
      var arrName = getArrayValue(data, "name");
      var arrValue = getArrayValue(data, "value");
      var sumValue = eval(arrValue.join('+'));
      var objData = array2obj(data, "name");
      var optionData = getData(data);
      function getArrayValue (array, key) {
        key = key || "value";
        var res = [];
        if (array) {
          array.forEach(function (t) {
            res.push(t[key]);
          });
        }
        return res;
      }
      function array2obj (array, key) {
        var resObj = {};
        for (var i = 0; i < array.length; i++) {
          resObj[array[i][key]] = array[i];
        }
        return resObj;
      }
      function getData (data) {
        var res = {
          series: [],
          yAxis: []
        };
        for (let i = 0; i < data.length; i++) {
          res.series.push({
            name: '',
            type: 'pie',
            clockWise: true, //顺时加载
            hoverAnimation: false, //鼠标移入变大
            radius: [63 - i * 15 + '%', 58 - i * 15 + '%'],
            center: ["30%", "55%"],
            label: {
              show: false
            },
            itemStyle: {
              label: {
                show: false,
              },
              labelLine: {
                show: false
              },
              borderWidth: 5,
            },
            data: [{
              value: data[i].value,
              name: data[i].name
            }, {
              value: sumValue - data[i].value,
              name: '',
              itemStyle: {
                color: "rgba(0,0,0,0)",
                borderWidth: 0
              },
              tooltip: {
                show: false
              },
              hoverAnimation: false
            }]
          });
        }
        return res;
      }
      let option = {
        title: {
          show: Object.keys(optionData.series).length == 0,
          textStyle: {
            color: 'grey',
            fontSize: 20
          },
          text: '暂无数据!',
          left: 'center',
          top: 'center'
        },
        legend: {
          show: true,
          icon: "circle",
          top: "center",
          left: '50%',
          data: arrName,
          width: 70,
          padding: [0, 5],
          itemGap: 20,
          formatter: function (name) {
            return "{title|" + name + "}  {value|" + (objData[name].percentage) + "}{title|%}"
          },
          textStyle: {
            rich: {
              title: {
                fontSize: 16,
                lineHeight: 5,
                color: "rgba(255,255,255,1)"
              },
              value: {
                fontSize: 16,
                lineHeight: 5,
                color: "#fff"
              }
            }
          },
        },
        tooltip: {
          show: true,
          trigger: "item",
          formatter: "{a}<br>{b}:{c}({d}%)"
        },
        color: ['rgba(123,86,241,1)', 'rgba(178,221,157,1)', 'rgba(8,246,228,1)', 'rgba(50,111,220,1)'],
        grid: {
          top: '16%',
          bottom: '53%',
          left: "30%",
          containLabel: false
        },
        yAxis: [{
          type: 'category',
          inverse: true,
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            interval: 0,
            inside: false,
            textStyle: {
              color: "#fff",
              fontSize: 16,
            },
            show: true
          },
          data: optionData.yAxis
        }],
        xAxis: [{
          show: false
        }],
        series: optionData.series
      };
      myecharts.setOption(option);
    },
    // 退款金额分布
    refundPlanPie () {
      let myecharts = echarts.init(document.getElementById('mainSecond'));
      let option = {
        tooltip: {
          trigger: 'item'
        },
        title: {
          show: this.pieseriesData.length == 0,
          textStyle: {
            color: 'grey',
            fontSize: 20
          },
          text: '暂无数据!',
          left: 'center',
          top: 'center'
        },
        legend: {
          // top: '5%',
          top: "center",
          left: '72%',
          orient: 'vertical',
          y: 'center',    //延Y轴居中
          x: 'right',//居右显示
          width: 70,
          align: 'left',//图例在文字左边
          icon: 'circle',//圆形图例
          textStyle: { //图例文字的样式
            color: 'white',
            fontSize: 16
          },
          data: this.pielegendData,
          formatter: (name) => {
            const item = this.pieseriesData.filter((item) => item.name === name)[0];
            return `${item.name} ${item.percentage}%`;
          },
        },
        color: ['rgb(123,86,241,1)', 'rgb(255,188,0,1)', 'rgb(8,246,228,1)', 'rgb(50,111,220,1)',],
        grid: {
          top: '16%',
          bottom: '53%',
          left: "30%",
          containLabel: false
        },
        series: [
          {
            name: '退款金额',
            type: 'pie',
            radius: ['60%', '55%'],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 10,
              borderColor: '#fff',
              borderWidth: 0
            },
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '20',
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: this.pieseriesData
          }
        ]
      };
      myecharts.setOption(option);
    },

    // 退款渠道
    queryRefundChanel () {
      const info = {
        columnName: ['value', 'name'],
        tableName: 'tb_dict',
        whereStr: [
          {
            colName: 'parent_id',
            value: '58184EF53AA9411AB1A2F49E6DC3D223',
          },
        ],
      }
      this.$queryDict.queryDict(info).then((res) => {
        this.refundChannelList = res.resultEntity
      })
    },
    // 退款进度
    queryRefundPlan () {
      const info = {
        columnName: ['value', 'name'],
        tableName: 'tb_dict',
        whereStr: [
          {
            colName: 'parent_id',
            value: '0B412097F0D6486888057ADFCCC7A442',
          },
        ],
      }
      this.$queryDict.queryDict(info).then((res) => {
        this.refundPlanList = res.resultEntity
      })
    },
  },
}
</script>
<style lang='scss' scoped>
$topSelectHeight: 135px;
//@import url(); 引入公共css类
.mainBody {
  height: 100%;
  .middle {
    width: 100%;
    height: 200px;
    display: flex;
    .firstPie {
      width: 49%;
      height: 200px;
      @include background('blockBg');
      .firstPieTitile {
        padding-top: 5px;
        margin-left: 24px;
      }
      .firstPieItem {
        width: 400px;
        padding-left: 100px;
        padding-bottom: 30px;
        margin-top: 0px;
      }
    }
    .secondPie {
      width: 49%;
      height: 200px;
      margin-left: 2%;
      @include background('blockBg');
      .secondPieTitle {
        padding-top: 5px;
        margin-left: 24px;
      }
      .secondPieContent {
        display: flex;
        padding-top: 5px;
        .secondPieContent-left {
          .tilte {
            margin-top: 13px;
            margin-left: 60px;
            font-size: 12px;
          }
          .content {
            width: 130px;
            .money {
              margin-left: 60px;
              color: #08f6e4;
              font-size: 28px;
            }
            .moneyText {
              color: #08f6e4;
              font-size: 12px;
              margin-left: 2px;
            }
          }
        }
        .secondPieContent-mid {
          width: 2px;
          height: 140px;
          margin-left: 50px;
          opacity: 0.69;
          background-image: linear-gradient(
            180deg,
            rgba(73, 138, 199, 0) 3%,
            #3b80e3 53%,
            rgba(19, 189, 19, 0) 100%
          );
        }
        .secondPieContent-right {
          height: 100%;
          padding-bottom: 30px;
        }
      }
    }
  }
  // 顶部查询条件
  .topSelectTwoLine {
    width: 100%;
    height: $topSelectHeight;
    @include background('blockBg');
    padding-top: 24.3px;
    padding-left: 21px;
    margin-top: 20px;
    .topSecondLine {
      margin-top: 19.7px;
    }
    .topButton {
      display: flex;
    }
  }
  .tableRow {
    width: 100%;
    @include background('blockBg');
    margin-top: 16px;
    padding-left: 20.2px;
    padding-right: 20.2px;
    padding-top: 5px;
    .tableTitle {
      height: 32px;
      margin-top: 15px;
      .tableTitleButton {
        float: right;
      }
    }
    .tableContent {
      width: 100%;
      height: 100%;
      display: flex;
      margin-top: 10px;
      .tableContent-left {
        width: 30%;
        margin-bottom: 20px;
      }
      .tableContent-right {
        width: 70%;
      }
    }
  }
}
.dt-button-box {
  display: flex;
  justify-content: flex-end;
}
</style>